import { Suspense } from 'react';

import { keycloak } from 'lib/keycloak';

// import { Button } from 'antd';
// import { keycloakProdCfg, keycloakTestCfg } from 'lib/keycloak';
// import { setSessionStorage } from 'lib/utils';
import { ModalErrorFallback } from 'components/ErrorFallback';
import { SuspenseLoader } from 'components/SuspenseLoader';

import { ModalRoot } from 'core/modals/ModalRoot';

import { AppLayout } from './AppLayout';
import { AppRoutes } from './AppRoutes';
import { KeycloakWrapper } from './KeycloakWrapper';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ErrorBoundary } from '@sentry/react';


const isProd = process.env.REACT_APP_AUTH_STRATEGY === 'SSO'

function App() {
  // const cookies = props?.cookies || {};


  if (isProd) {
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        onTokens={({ token }) => {
          localStorage.setItem("bearer-token", token);
        }}
        // onEvent={(event, error) => {
        //   console.log('EVENT', event)
        //   console.log("ERROR", error)
        // }}
        initOptions={{
          onLoad: 'login-required',
          // checkLoginIframe: false,
        }}
      >
        <KeycloakWrapper children={
          <Suspense fallback={<SuspenseLoader />}>
            <AppLayout>
              <AppRoutes />
            </AppLayout>
            <ErrorBoundary fallback={ModalErrorFallback}>
              <ModalRoot />
            </ErrorBoundary>
          </Suspense>
        }
        />
      </ReactKeycloakProvider>
    )
  }

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <AppLayout>
        <AppRoutes />
      </AppLayout>
      <ErrorBoundary fallback={ModalErrorFallback}>
        <ModalRoot />
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
