import { useEffect } from 'react';

import { useAppDispatch } from 'lib/hooks';

import { authApi } from 'core/auth/authApi';

interface IProps {}

const isLocal = process.env.REACT_APP_AUTH_STRATEGY === 'LOCAL';

export function CheckAuth(props: IProps) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    isLocal && dispatch(authApi.checkAuth());
  }, [dispatch]);

  return null;
}
