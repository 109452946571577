import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { keyBy } from 'lodash';

import { tasksApi } from 'core/tasks/tasksApi';

import { EvaluationGroup, Task, TaskKind } from 'types/DTO';

const sliceName = 'tasks';
const adapter = createEntityAdapter<Task>();

interface InitialSate {
  tasksKinds: Record<TaskKind['value'], TaskKind>;
  searchId: Task['id'];
}

const initialSate: InitialSate = {
  tasksKinds: null,
  searchId: null,
};

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(initialSate),
  reducers: {
    setSearchId: (state, action) => {
      state.searchId = action.payload;
    },
    clearTasks: (state) => {
      adapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(tasksApi.kinds.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.tasksKinds = keyBy(data, 'value');
      })
      .addCase(tasksApi.fetchId.fulfilled, (state, action) => {
        const { data } = action.payload;
        adapter.upsertOne(state, data);
      })
      .addCase(tasksApi.fetch.fulfilled, (state, action) => {
        const { data } = action.payload;
        adapter.setAll(state, data);
      })
      .addCase(tasksApi.update.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { id, ...changes } = data;
        adapter.updateOne(state, { id, changes });
      });
  },
});

const adapterSelectors = adapter.getSelectors(
  (state: RootState) => state[sliceName]
);

export const tasksSelectors = {
  ...adapterSelectors,
  selectById: (id: EvaluationGroup['id']) => (state: RootState) =>
    adapterSelectors.selectById(state, id),
  selectKinds: (state: RootState) => state[sliceName].tasksKinds,
  selectSearchId: (state: RootState) => state[sliceName].searchId,
};

export const tasksReducer = slice.reducer;
export const tasksActions = slice.actions;
