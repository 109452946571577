import { createAsyncThunk } from '@reduxjs/toolkit';

import { http, transformResponse } from 'lib/http';

import { AuthUser } from 'types/DTO';

export interface ISignInResponse {
  success: boolean;
  info: string;
  user: AuthUser;
}

export interface ISignInRequest {
  email: string;
  password: string;
  admin_secret: string;
}

export interface ISignOutResponse {
  success: boolean;
  info: string;
}

const prefix = 'auth' as const;

const signIn = createAsyncThunk<ISignInResponse, ISignInRequest>(
  `${prefix}/sign_in`,
  (body, thunkAPI) => {
    return http
      .post('/sign_in', body)
      .then(transformResponse)
      .catch(thunkAPI.rejectWithValue);
  }
);

const signOut = createAsyncThunk<ISignOutResponse>(
  `${prefix}/sign_out`,
  (_, thunkAPI) => {
    return http
      .delete('/sign_out')
      .then(transformResponse)
      .catch(thunkAPI.rejectWithValue);
  }
);

const checkAuth = createAsyncThunk<void>(
  `${prefix}/check_auth`,
  (_, thunkAPI) => {
    return http
      .get('/check_auth')
      .then(transformResponse)
      .catch(thunkAPI.rejectWithValue);
  },
  {
    condition(arg, { getState }) {
      const { thunkStatuses } = getState() as RootState;
      const fetchStatus = thunkStatuses[`${prefix}/check_auth`];
      if (fetchStatus === 'fulfilled' || fetchStatus === 'pending') {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const authApi = {
  signIn,
  signOut,
  checkAuth,
};
