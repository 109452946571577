import {
  AsyncThunk,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
} from '@reduxjs/toolkit';

import { transformResponse } from 'lib/http';

interface ThunkAPIConfig {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface AsyncThunkOptions<T, U> {}

/**
 * Обертка для создания санка, выполняет обработку ошибок и маппит респонс
 * @param type - префикс для экшена
 * @param request
 * @param options
 */
export const createAppThunk = <Returned, ThunkArg = any>(
  type: string,
  request: AsyncThunkPayloadCreator<Returned, ThunkArg>,
  options?: AsyncThunkOptions<ThunkArg, ThunkAPIConfig>
): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> => {
  return createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(
    type,
    async (arg, thunkAPI) => {
      try {
        const res = await request(arg, thunkAPI);
        return transformResponse(res);
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    },
    options
  );
};
