import { lazy } from 'react';
import {
  AiOutlineFileDone,
  AiOutlineTeam,
  RiUserStarLine,
} from 'react-icons/all';


/**
 * Роуты маппятся в core/AppLayout
 * если tittle = null то данный роут не отображается в меню навигации
 */
export const routes = [
  {
    href: '/groups',
    title: 'Группы',
    Icon: AiOutlineTeam,
    Component: lazy(() => import('pages/groups')),
  },
  {
    href: '/tasks',
    title: 'Задачи',
    Icon: AiOutlineFileDone,
    Component: lazy(() => import('pages/tasks')),
  },
  {
    href: '/sessions',
    title: 'Сессии',
    Icon: RiUserStarLine,
    Component: lazy(() => import('pages/sessions')),
  },
  {
    href: '/login',
    title: null,
    Icon: null,
    Component: lazy(() => import('pages/login')),
  },
];
