import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { usersApi } from 'core/users/usersApi';

import { User } from 'types/DTO';

const sliceName = 'users';

const adapter = createEntityAdapter<User>();

const slice = createSlice({
  name: sliceName,
  initialState: adapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(usersApi.fetchId.fulfilled, (state, action) => {
      const { data } = action.payload;
      adapter.upsertOne(state, data);
    });
  },
});

const adapterSelectors = adapter.getSelectors(
  (state: RootState) => state[sliceName]
);

export const usersSelectors = {
  ...adapterSelectors,
  selectById: (id: User['id']) => (state: RootState) =>
    adapterSelectors.selectById(state, id),
};

export const usersReducer = slice.reducer;
export const usersActions = slice.actions;
