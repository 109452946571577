import { isRejectedWithValue, MiddlewareAPI } from '@reduxjs/toolkit';

import { notification } from 'antd';

import { tablesReducer } from 'store/tablesSlice';
import { thunkStatusesReducer } from 'store/thunkStatusesSlice';

import { groupsReducer } from 'core/groups/groupsSlice';
import { modalsReducer } from 'core/modals/modalsSlice';
import { sessionsReducer } from 'core/sessions/sessionsSlice';
import { tasksReducer } from 'core/tasks/tasksSlice';
import { usersReducer } from 'core/users/usersSlice';

export const rootReducer = {
  modals: modalsReducer,
  tables: tablesReducer,
  thunkStatuses: thunkStatusesReducer,
  users: usersReducer,
  sessions: sessionsReducer,
  groups: groupsReducer,
  tasks: tasksReducer,
};

export const asyncErrorLogger = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.dir(action.payload);
    notification.error({
      message: 'Ошибка запроса',
      description:
        action.payload?.response?.data?.message || 'Неизвестная ошибка',
      duration: 10,
    });
  }

  return next(action);
};

export const middlewares = [asyncErrorLogger];
