import { useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AsyncThunk } from '@reduxjs/toolkit';

import { thunkStatusesSelectors } from 'store/thunkStatusesSlice';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useAppDispatch() {
  return useDispatch<AppDispatch>();
}

/**
 * Получить статус thunk
 * @param thunkAction - асинхронный экшен
 */
export const useThunkStatus = (thunkAction: AsyncThunk<any, any, any>) => {
  const actionStatus = useAppSelector(
    thunkStatusesSelectors.selectByType(thunkAction.typePrefix)
  );

  const result = useMemo(() => {
    return {
      actionStatus,
      isPending: actionStatus === 'pending',
      isSuccess: actionStatus === 'fulfilled',
    }
  }, [actionStatus])

  return result;
};

/**
 * Вернет обобщенный статус по всем переданным санкам
 * @param thunkActions
 */
export const useThunksState = (
  ...thunkActions: AsyncThunk<any, any, any>[]
) => {
  const thunkStatuses = useAppSelector(thunkStatusesSelectors.selectState);
  const statuses = thunkActions.map(
    (thunkAction) => thunkStatuses[thunkAction.typePrefix]
  );

  if (statuses.every((status) => !status)) {
    return {
      isPending: false,
      isFetched: false,
    };
  }

  if (statuses.some((status) => status === 'pending')) {
    return {
      isPending: true,
      isFetched: false,
    };
  }

  if (
    statuses.every(
      (status) => status === 'fulfilled' || status === 'rejected' || !status
    )
  ) {
    return {
      isPending: false,
      isFetched: true,
    };
  }

  return {
    isPending: false,
    isFetched: false,
  };
};
