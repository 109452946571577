import { get } from 'lodash';

import { GroupEditFormModal } from 'core/modals/groups/GroupEditFormModal';
import { SessionEditFormModal } from 'core/modals/sessions/SessionEditFormModal';
import { TaskEditFormModal } from 'core/modals/tasks/TaskEditFormModal';

const modalsPerType = {
  group: {
    edit: GroupEditFormModal,
  },
  task: {
    edit: TaskEditFormModal,
  },
  session: {
    edit: SessionEditFormModal,
  },
};

export function getModalComponent(modalType: TModalType) {
  return get(modalsPerType, modalType);
}

declare global {
  type TModalType = `${keyof typeof modalsPerType}.${keyof typeof modalsPerType[keyof typeof modalsPerType]}`;
}
