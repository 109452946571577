import { setLocale } from 'yup';

// https://github.com/jquense/yup#using-a-custom-locale-dictionary
export const setupYup = () => {
  setLocale({
    mixed: {
      required: 'Обязательное поле',
    },
  });
};
