import { lazy, Suspense } from 'react';

import { Modal } from 'antd';

import { SuspenseLoader } from 'components/SuspenseLoader';

import { IModalProps } from 'types';

const SessionEditForm = lazy(() =>
  import('core/sessions/SessionEditForm').then((module) => ({
    default: module.SessionEditForm,
  }))
);

export function SessionEditFormModal(props: IModalProps) {
  const { data, onCancel, ...modalProps } = props;

  return (
    <Modal
      visible
      width={640}
      {...modalProps}
      onCancel={onCancel}
      title="Редактирование сессии"
    >
      <Suspense fallback={<SuspenseLoader />}>
        <SessionEditForm {...data} />
      </Suspense>
    </Modal>
  );
}
