import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { entries } from 'lodash';

const sliceName = 'tables';

type Selection = number[];
type Filters = Record<string, any>;
export type TableNames = keyof IState;

interface IState {
  group: {
    selection: Selection;
    filters: Filters;
  };
  task: {
    selection: Selection;
    filters: Filters;
  };
  session: {
    selection: Selection;
    filters: Filters;
  };
}

const initialState: IState = {
  group: {
    selection: [],
    filters: {},
  },
  task: {
    selection: [],
    filters: {},
  },
  session: {
    selection: [],
    filters: {},
  },
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSelection(
      state,
      action: PayloadAction<{
        table: TableNames;
        selection: Selection;
      }>
    ) {
      const { payload } = action;
      state[payload.table].selection = payload.selection;
    },
    resetSelection(
      state,
      action: PayloadAction<{
        table: TableNames;
      }>
    ) {
      const { payload } = action;
      state[payload.table].selection = [];
    },
    setFilters(
      state,
      action: PayloadAction<{
        table: TableNames;
        filters: Filters;
        reset?: boolean;
      }>
    ) {
      const { payload } = action;
      if (payload.reset === true) {
        state[payload.table].filters = payload.filters;
      } else {
        entries(payload.filters).forEach(([key, value]) => {
          state[payload.table].filters[key] = value;
        });
      }
    },
    resetFilters(
      state,
      action: PayloadAction<{
        table: TableNames;
      }>
    ) {
      const { payload } = action;
      state[payload.table].filters = {};
    },
  },
});

export const tablesSelectors = {
  selectByTable: (table: TableNames) => (state: RootState) => {
    return state[sliceName][table];
  },
  selectFilterByColumn: (table: TableNames, column: keyof Filters) => (
    state: RootState
  ) => {
    return state[sliceName][table].filters[column] ?? null;
  },
};

export const tablesReducer = slice.reducer;
export const tablesActions = slice.actions;
