import { Button, Popconfirm } from 'antd';

import { LoginOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';




export const LogoutButtonProd = () => {

    const { keycloak } = useKeycloak();

    const handleLogout = () => {
        keycloak.logout()
    };

    return (
        <Popconfirm
            placement="bottomLeft"
            title="Вы действительно хотите выйти?"
            onConfirm={handleLogout}
            icon={<QuestionCircleOutlined />}
        >
            <Button type="primary" size="large" icon={<LoginOutlined />}>
                Выход
            </Button>
        </Popconfirm>
    );
};
