import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalProps } from 'antd';

const sliceName = 'modals';

interface IModalState {
  type: null | TModalType;
  data?: Record<string, any>;
  modalProps?: ModalProps;
}

const initialState: IModalState = {
  type: null,
  data: {},
  modalProps: {},
};

const modalsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    showModal: (state, { payload }: PayloadAction<IModalState>) => {
      const { type, data = {}, modalProps = {} } = payload;
      state.type = type;
      state.data = data;
      // @ts-ignore
      state.modalProps = modalProps;
    },
    hideModal: () => {
      return {
        type: null,
        data: {},
        modalProps: {},
      };
    },
  },
});

export const modalsSelectors = {
  selectModalState: (state: RootState) => state[sliceName],
};
export const modalsActions = modalsSlice.actions;
export const modalsReducer = modalsSlice.reducer;
