import { createAsyncThunk } from '@reduxjs/toolkit';

import { http, transformResponse } from 'lib/http';

import { createCrud, makePrefix } from 'core/api/crudHelpers';

import { CareerLevel, Track, UserSession } from 'types/DTO';

const url = '/user_sessions';
const prefix = makePrefix(url);

const availableTracks = createAsyncThunk<
  { data: Track[] },
  { id: UserSession['id'] }
>(
  `${prefix}/available_tracks`,
  ({ id }, thunkAPI) => {
    return http
      .get(`${url}/${id}/available_tracks`)
      .then(transformResponse)
      .catch(thunkAPI.rejectWithValue);
  },
  {
    condition(arg, api) {
      const state = api.getState() as RootState;
      const tracks = state.sessions.tracksPerSession;
      if (arg.id in tracks) {
        // Already fetched, don't need to re-fetch
        return false;
      }
    },
    dispatchConditionRejection: false,
  }
);

const availableCareerLevels = createAsyncThunk<
  { data: CareerLevel[] },
  { id: UserSession['id'] }
>(
  `${prefix}/available_career_levels`,
  ({ id }, thunkAPI) => {
    return http
      .get(`${url}/${id}/available_career_levels`)
      .then(transformResponse)
      .catch(thunkAPI.rejectWithValue);
  },
  {
    condition(arg, api) {
      const state = api.getState() as RootState;
      const levels = state.sessions.careerLevelsPerSession;
      if (arg.id in levels) {
        // Already fetched, don't need to re-fetch
        return false;
      }
    },
    dispatchConditionRejection: false,
  }
);

export const crud = createCrud<
  UserSession,
  UserSession['id'],
  {
    user_session: Partial<UserSession>;
  }
>({ url, searchFields: ['name'] });

export const userSessionsApi = {
  ...crud,
  availableTracks,
  availableCareerLevels,
};
