import Keycloak from "keycloak-js";

// // Настройки для тестового стенда
// export const keycloakTestCfg = {
//   url: 'https://test-keycloak.digitalleague.ru/auth/',
//   realm: 'league-test-sso',
//   clientId: 'academy-admin-test',
// }

// Настройки для прода
export const keycloakProdCfg = {
  url: 'https://sso.digitalleague.ru/auth/',
  realm: 'league-sso',
  clientId: 'academy-admin',
};



export const keycloak = new Keycloak(keycloakProdCfg);


// {
//   "realm": "league-test-sso",
//   "auth-server-url": "https://test-keycloak.digitalleague.ru/auth/",
//   "ssl-required": "external",
//   "resource": "academy-admin-test",
//   "public-client": true,
//   "confidential-port": 0
// }


// {
//   "realm": "league-sso",
//   "auth-server-url": "https://sso.digitalleague.ru/auth/",
//   "ssl-required": "external",
//   "resource": "academy-admin",
//   "public-client": true,
//   "confidential-port": 0
// }