import { createCrud } from 'core/api/crudHelpers';

import { EvaluationGroup } from 'types/DTO';

const url = '/evaluation_groups';

export const groupsApi = createCrud<
  EvaluationGroup,
  EvaluationGroup['id'],
  {
    evaluation_group: Partial<EvaluationGroup>;
  }
>({ url, searchFields: ['name'] });
