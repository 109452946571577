import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { sortBy } from 'lodash';

import { userSessionsApi } from 'core/sessions/userSessionsApi';

import { CareerLevel, Track, User, UserSession } from 'types/DTO';

const sliceName = 'sessions';
const sessionsAdapter = createEntityAdapter<UserSession>();

interface InitialSate {
  searchId: User['id'];
  tracksPerSession: Record<UserSession['id'], Track[]>;
  careerLevelsPerSession: Record<UserSession['id'], CareerLevel[]>;
}

const initialSate: InitialSate = {
  searchId: null,
  tracksPerSession: {},
  careerLevelsPerSession: {},
};

const slice = createSlice({
  name: sliceName,
  initialState: sessionsAdapter.getInitialState(initialSate),
  reducers: {
    setSearchId: (state, action) => {
      state.searchId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userSessionsApi.fetch.fulfilled, (state, action) => {
        const { data } = action.payload;
        sessionsAdapter.setAll(state, data);
      })
      .addCase(userSessionsApi.update.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { id, ...changes } = data;
        sessionsAdapter.updateOne(state, { id, changes });
      })
      .addCase(userSessionsApi.availableTracks.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { arg } = action.meta;
        state.tracksPerSession[arg.id] = data;
      })
      .addCase(
        userSessionsApi.availableCareerLevels.fulfilled,
        (state, action) => {
          const { data } = action.payload;
          const { arg } = action.meta;
          state.careerLevelsPerSession[arg.id] = sortBy(data, 'value');
        }
      );
  },
});

const adapterSelectors = sessionsAdapter.getSelectors(
  (state: RootState) => state[sliceName]
);

export const sessionsSelectors = {
  ...adapterSelectors,
  selectById: (id: UserSession['id']) => (state: RootState) =>
    adapterSelectors.selectById(state, id),
  selectSearchId: (state: RootState) => state[sliceName].searchId,
  selectTracksBySessionId: (id: UserSession['id']) => (state: RootState) =>
    state[sliceName].tracksPerSession[id],
  selectCareerLevelsBySessionId: (id: UserSession['id']) => (
    state: RootState
  ) => state[sliceName].careerLevelsPerSession[id],
};

export const sessionsReducer = slice.reducer;
export const sessionsActions = slice.actions;
