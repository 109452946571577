import { http } from 'lib/http';

import { AxiosRequestConfig } from 'axios';

type ConfigType = AxiosRequestConfig;

const evaluationGroups = {
  fetch: (config?: ConfigType) => http.get('/evaluation_groups', config),
  fetchId: (id, config?: ConfigType) =>
    http.get(`/evaluation_groups/${id}`, config),
  update: (id, config?: ConfigType) =>
    http.put(`/evaluation_groups/${id}`, config),
};

const userSessions = {
  fetch: (config?: ConfigType) => http.get('/user_sessions', config),
  fetchId: (id, config?: ConfigType) =>
    http.get(`/user_sessions/${id}`, config),
  update: (id, config?: ConfigType) => http.put(`/user_sessions/${id}`, config),
  fetchAvailableTracks: (id, config?: ConfigType) =>
    http.get(`/user_sessions/${id}/available_tracks`, config),
  fetchAvailableCareerLevels: (id, config?: ConfigType) =>
    http.get(`/user_sessions/${id}/available_career_levels`, config),
};

const tasks = {
  fetch: (config?: ConfigType) => http.get('/tasks', config),
  fetchId: (id, config?: ConfigType) => http.get(`/tasks/${id}`, config),
  update: (id, config?: ConfigType) => http.put(`/tasks/${id}`, config),
  fetchTasksKinds: (config?: ConfigType) =>
    http.get(`/tasks/task_kind_dict`, config),
};

const users = {
  fetch: (config?: ConfigType) => http.get('/users', config),
  suggestions: (config?: ConfigType) => http.get('/users/suggestions', config),
  fetchId: (id, config?: ConfigType) => http.get(`/users/${id}`, config),
  update: (id, config?: ConfigType) => http.put(`/users/${id}`, config),
};

export const apiRoutes = {
  evaluationGroups,
  userSessions,
  tasks,
  users,
};
