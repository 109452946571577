import { keycloak } from './keycloak';

import axios from 'axios';
import cleanDeep from 'clean-deep';
import Qs from 'qs';
import { resolve } from 'url';
// import { keycloak } from './keycloak';
// import { getSessionStorage } from './utils';

const isSSO = process.env.REACT_APP_AUTH_STRATEGY === 'SSO';
const isLocal = process.env.REACT_APP_AUTH_STRATEGY === 'LOCAL';

const API_URL = isSSO
  ? process.env.REACT_APP_API_URL_SSO
  : process.env.REACT_APP_API_URL_LOCAL;

const BASE_URL = resolve(API_URL, '/admin');

let browserHistory;

export const setupApi = (_browserHistory) => {
  browserHistory = _browserHistory;
};

function redirectToLogin() {
  const { pathname } = browserHistory.location;
  if (pathname === '/login') {
    return;
  }

  isLocal &&
    browserHistory.replace('/login', {
      prevPage: pathname,
    });
}

export const http = axios.create({
  baseURL: BASE_URL,
  withCredentials: !isSSO,
  paramsSerializer(params) {
    const filtered = cleanDeep(params);
    return Qs.stringify(filtered, { arrayFormat: 'brackets' });
  },
});

http.interceptors.request.use((config) => {
  // config.headers.Authorization = getCookie('accessToken');

  if (isSSO) {

    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: String(localStorage.getItem('bearer-token')),
    }
  }

  return config;
});


const onFulfilled = (response: any) => {
  return response;
};

const onRejected = (error: any) => {
  switch (error.response?.status) {
    case 401: {
      // window.location.href = error.response.headers['x-redirect'];


      if (process.env.REACT_APP_AUTH_STRATEGY === 'sso') {
        keycloak.logout()
      }

      break;
    }

    default: {
      return Promise.reject(error);
    }
  }
};



http.interceptors.response.use(onFulfilled, onRejected);

const handleError = (error) => {
  if (error?.response?.status === 401) {
    redirectToLogin();
  }
  return Promise.reject(error);
};

const addResponseRedirectInterceptor = (axiosObject) => {
  axiosObject.interceptors.response.use((response) => response, handleError);
};

addResponseRedirectInterceptor(http);

export function transformResponse(res) {
  return res.data;
}
