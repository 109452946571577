import { createBrowserHistory } from 'history';

/**
 * Сделано для того, чтобы не было возможности обратиться глобально
 * Если нужно передать в другой модуль см. src/lib/http
 */
export function setupBrowserHistory() {
  const browserHistory = createBrowserHistory();

  return browserHistory;
}
