import { identity, omit, trimStart } from 'lodash';

import { http } from 'lib/http';

import { createAppThunk } from 'core/api/createAppThunk';

export function makePrefix(url) {
  return trimStart(url, '/');
}

function makeQueryStringData(select: object, searchFields: string[]) {
  const search = searchFields.reduce((acc, key) => {
    return {
      ...acc,
      [key]: select[key],
    };
  }, {});

  return {
    select_by: omit(select, searchFields),
    search_query: search,
  };
}

export function createFetchThunk<EntityType>(
  url: string,
  searchFields: string[] = [],
  mapSearchParams: (searchParams) => any = identity
) {
  return createAppThunk<
    { data: EntityType[] },
    {
      searchParams?: Partial<EntityType>;
      params?: any;
    }
  >(
    `${makePrefix(url)}/fetch`,
    async ({ searchParams = {}, params = {} }, thunkAPI) => {
      const mapped = await mapSearchParams(searchParams);
      const qs = makeQueryStringData(mapped, searchFields);
      return http.get(url, { params: { ...qs, ...params } });
    }
  );
}

export function createFetchIdThunk<EntityType, IdType>(url: string) {
  return createAppThunk<{ data: EntityType }, { id: IdType }>(
    `${makePrefix(url)}/fetchId`,
    ({ id }, thunkAPI) => {
      return http.get(`${url}/${id}`);
    }
  );
}

export function createUpdateThunk<EntityType, IdType, BodyType>(url: string) {
  return createAppThunk<{ data: EntityType }, { id: IdType; body: BodyType }>(
    `${makePrefix(url)}/update`,
    ({ id, body }, thunkAPI) => {
      return http.put(`${url}/${id}`, body);
    }
  );
}

export function createCrud<Entity, ID, UpdateBody>({
  url,
  searchFields = [] as string[],
  mapSearchParams = identity as (searchParams) => object,
}) {
  return {
    fetch: createFetchThunk<Entity>(url, searchFields, mapSearchParams),
    fetchId: createFetchIdThunk<Entity, ID>(url),
    update: createUpdateThunk<Entity, ID, UpdateBody>(url),
  };
}
