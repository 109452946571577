import ReactDOM from 'react-dom';
import { IconContext } from 'react-icons';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { ConfigProvider as AntdConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';

import { store } from 'store';

import { setupAntd } from 'lib/antd';
import { setupBrowserHistory } from 'lib/history';
import { setupApi } from 'lib/http';
import { setupYup } from 'lib/yup';

import App from 'core/App';

import * as serviceWorker from './serviceWorker';
import 'index.less';
import 'fontsource-roboto';

import * as Sentry from '@sentry/react';

// import { keycloak } from 'lib/keycloak';



export default function init() {
  const browserHistory = setupBrowserHistory();
  setupApi(browserHistory);
  setupAntd(browserHistory);
  setupYup();

  ReactDOM.render(
    <AntdConfigProvider locale={ruRU} input={{ autoComplete: 'off' }}>
      <IconContext.Provider
        value={{ size: '1.5em', style: { verticalAlign: 'middle' } }}
      >
        <Router history={browserHistory}>
          <Provider store={store}>
            <App />
          </Provider>
        </Router>
      </IconContext.Provider>
    </AntdConfigProvider>,
    document.getElementById('root')
  );
}

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_ENABLE_MOCK === 'true'
) {
  const { worker } = require('./mocks/browser');
  worker.start().then(init);
  serviceWorker.unregister();
} else {
  init();
  // включает кэш
  // serviceWorker.register();
}

if (
  process.env.NODE_ENV === 'production' &&
  typeof process.env.REACT_APP_SENTRY_DSN === 'string'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_CURRENT_ENV,
  });
}
export { useAppDispatch } from 'lib/hooks';
export { useAppSelector } from 'lib/hooks';
