import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { middlewares, rootReducer } from './rootReducer';

import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: false,
      thunk: true,
    }).concat(middlewares),
  enhancers: [sentryReduxEnhancer],
});

declare global {
  type RootState = ReturnType<typeof store.getState>;
  type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
  >;
  type AppDispatch = typeof store.dispatch;
}

declare module '@reduxjs/toolkit' {
  type AsyncThunkConfig = {
    state?: RootState;
    dispatch?: AppDispatch;
    extra?: null;
    rejectValue?: AxiosError;
  };
}

declare module 'react-redux' {
  interface DefaultRootState extends RootState {}
}
