import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { routes } from 'pages/routes';

import { ErrorFallback } from 'components/ErrorFallback';
import { SuspenseLoader } from 'components/SuspenseLoader';

import { ErrorBoundary } from '@sentry/react';

export const AppRoutes: React.FC = () => {
  return (
    <Switch>
      {routes.map(({ href, Component }) => {
        return (
          <Route path={href} key={href}>
            <Suspense fallback={<SuspenseLoader />}>
              <ErrorBoundary fallback={ErrorFallback}>
                <Component />
              </ErrorBoundary>
            </Suspense>
          </Route>
        );
      })}
      <Route>
        <Redirect to="/groups" />
      </Route>
    </Switch>
  );
};
