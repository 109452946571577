import { Modal, notification } from 'antd';

export function setupAntd(browserHistory) {
  notification.config({
    placement: 'bottomRight',
  });

  /**
   * При любой навигации, закрываются все модальные окна
   */
  browserHistory.listen(() => {
    Modal.destroyAll();
  });
}
