import React, { useEffect } from 'react';

import { useKeycloak } from '@react-keycloak/web'



interface IProps {
    children: React.ReactNode
}

export const KeycloakWrapper = ({ children }: IProps) => {

    const { initialized, keycloak } = useKeycloak();


    useEffect(() => {
        if (keycloak && initialized) {
            keycloak.onTokenExpired = () => keycloak.updateToken(5);
        }

        return () => {
            if (keycloak) keycloak.onTokenExpired = () => { };
        };
    }, [initialized, keycloak]);


    if (!initialized) {
        return <div>Loading...</div>
    }

    return (
        <>
            {children}
        </>
    )
}