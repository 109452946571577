import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from 'lib/hooks';

import { getModalComponent } from 'core/modals/index';
import { modalsActions, modalsSelectors } from 'core/modals/modalsSlice';

export function ModalRoot() {
  const dispatch = useAppDispatch();

  const { data, modalProps, type } = useAppSelector(
    modalsSelectors.selectModalState,
    shallowEqual
  );

  const clearModalState = () => dispatch(modalsActions.hideModal());

  const ModalComponent = getModalComponent(type);

  if (type === null) return null;

  return (
    <ModalComponent
      data={data}
      onCancel={clearModalState}
      footer={null}
      {...modalProps}
    />
  );
}
