import { apiRoutes } from 'core/api/apiRoutes';
import { createAppThunk } from 'core/api/createAppThunk';
import { usersSelectors } from 'core/users/usersSlice';

import { User } from 'types/DTO';

const prefix = 'users' as const;

const fetchId = createAppThunk<{ data: User }, { id: User['id'] }>(
  `${prefix}/fetchId`,
  ({ id }, thunkAPI) => {
    return apiRoutes.users.fetchId(id);
  },
  {
    condition(arg, { getState }) {
      const state = getState() as RootState;
      const fetchedUsersIds = usersSelectors.selectIds(state);
      if (fetchedUsersIds.includes(arg.id)) {
        // Already fetched, don't need to re-fetch
        return false;
      }
    },
  }
);

export const usersApi = {
  fetchId,
};
