import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Image, Layout, Menu } from 'antd';

import { routes } from 'pages/routes';

import { CheckAuth } from 'core/auth/CheckAuth';
import { LogoutButton } from 'core/auth/LogoutButton';

import logo from 'assets/logo.svg';

import { LogoutButtonProd } from './auth/LogoutButtonProd';

import Icon from '@ant-design/icons';

const SIDE_WIDTH = 200;
const COLLAPSED_SIDE_WIDTH = 60;

const getInitialCollapseState = () => {
  const collapsed = localStorage.getItem('isSideCollapsed') === 'true';
  return collapsed;
};


const isProd = process.env.REACT_APP_AUTH_STRATEGY === 'SSO'

export function AppLayout(props) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(getInitialCollapseState);

  // если страница логина, не рендерить
  if (location.pathname.startsWith('/login')) {
    return props.children;
  }

  const handleCollapse = (isCollapsed) => {
    setCollapsed(isCollapsed);
    localStorage.setItem('isSideCollapsed', isCollapsed.toString());
  };

  return (
    <Layout className="h-full">
      <CheckAuth />
      <Layout.Header className="bg-white px-0 flex justify-between items-center pr-5 border-b-1">
        <Image
          src={logo}
          width={SIDE_WIDTH - 15}
          preview={false}
          className="m-2"
        />

        {
          isProd
            ? <LogoutButtonProd />
            : <LogoutButton />
        }

        {/*<Menu mode="horizontal" className="block ml-5">*/}
        {/*  <Menu.Item key="1">nav 1</Menu.Item>*/}
        {/*  <Menu.Item key="2">nav 2</Menu.Item>*/}
        {/*  <Menu.Item key="3">nav 3</Menu.Item>*/}
        {/*</Menu>*/}
      </Layout.Header>
      <Layout hasSider>
        <Layout.Sider
          collapsible
          collapsed={collapsed}
          onCollapse={handleCollapse}
          className="bg-white"
          collapsedWidth={COLLAPSED_SIDE_WIDTH}
        >
          <Menu
            className="mt-5"
            mode="inline"
            selectedKeys={[location.pathname]}
            style={{
              width: collapsed ? COLLAPSED_SIDE_WIDTH + 1 : SIDE_WIDTH + 1,
            }}
          >
            {routes.map((route) => {
              if (route.title === null) return null;
              return (
                <Menu.Item
                  key={route.href}
                  icon={<Icon component={route.Icon} />}
                  className="px-5"
                >
                  <Link to={route.href}>{route.title}</Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </Layout.Sider>
        <Layout className="mx-5">
          {/*<Breadcrumb style={{ margin: '16px 0' }}>*/}
          {/*  <Breadcrumb.Item>User</Breadcrumb.Item>*/}
          {/*  <Breadcrumb.Item>Bill</Breadcrumb.Item>*/}
          {/*</Breadcrumb>*/}
          <Layout.Content
            className="h-full my-0 overflow-auto"
            style={{ maxWidth: 1700 }}
          >
            <br />
            {props.children}
            <br />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
