import { http } from 'lib/http';

import { apiRoutes } from 'core/api/apiRoutes';
import { createAppThunk } from 'core/api/createAppThunk';
import { createCrud, makePrefix } from 'core/api/crudHelpers';

import { Task, TaskKind } from 'types/DTO';

const url = '/tasks';
const prefix = makePrefix(url);

const kinds = createAppThunk<{ data: TaskKind[] }, void>(
  `${prefix}/task_kind_dict`,
  (_, thunkAPI) => {
    return apiRoutes.tasks.fetchTasksKinds();
  },
  {
    condition(arg, { getState }) {
      const { thunkStatuses } = getState() as RootState;
      const fetchStatus = thunkStatuses[`${prefix}/task_kind_dict`];
      if (fetchStatus === 'fulfilled' || fetchStatus === 'pending') {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

const mapFetchSearchParams = async (searchParams) => {
  if ('user_session_id' in searchParams) {
    if (searchParams.user_session_id !== null) {
      const userSessions = await http.get('/user_sessions', {
        params: { 'select_by[user_id]': searchParams.user_session_id },
      });

      const activeSession = userSessions.data.data.find(
        (i) => i.state === 'active'
      );
      const activeUserSessionId = activeSession?.id ?? 0;

      return { ...searchParams, user_session_id: activeUserSessionId };
    }
  }

  return searchParams;
};

const crud = createCrud<
  Task,
  Task['id'],
  {
    task: Partial<Task>;
  }
>({ url, mapSearchParams: mapFetchSearchParams });

export const tasksApi = {
  ...crud,
  kinds,
};
