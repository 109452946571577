import { Result } from 'antd';

export function ErrorFallback(props) {
  return (
    <Result
      status="error"
      title="Something went wrong."
      extra={props.error.message}
    />
  );
}

export function ModalErrorFallback(props) {
  return null;
}
